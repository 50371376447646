import React from "react";
import LanguageCard from "./LanguageCard";

function TagListing({
  category,
  tag,
  description,
  allLanguagesJson,
  languageEdges,
}) {
  const renderTag = () => {
    if (tag != null) {
      return (
        <div className="pb-8">
          <span className="prose prose-base uppercase font-bold">{`${tag} / ${category} / `}</span>
          <span>{allLanguagesJson.totalCount} total</span>
        </div>
      );
    }
  };

  return (
    <article className="container flex flex-col justify-between pt-12 mb-12">
      <h1 className="text-3xl pb-2 capitalize">{category}</h1>
      <p className="prose prose-slate prose-xl pb-6">{description}</p>
      {renderTag()}

      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 auto-rows-max items-stretch justify-between">
        {languageEdges.map((lang) => (
          <div className="w-full self-stretch h-full">
            <LanguageCard language={lang.node} type="list" />
          </div>
        ))}
      </div>
    </article>
  );
}

export default TagListing;
