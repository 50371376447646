import React from "react";
import IconPositive from "../Icons/IconPositive";
import IconNegative from "../Icons/IconNegative";
import IconWarning from "../Icons/IconWarning";

export const renderBarrierToEntryIcon = (barrier_to_entry) => {
  const barrierValue = barrier_to_entry?.value;
  if (barrierValue === "low") {
    return <IconPositive width="25" />;
  } else if (barrierValue === "medium") {
    return <IconWarning width="25" />;
  } else {
    return <IconNegative width="25" />;
  }
};

export const renderDemandIcon = (demand) => {
  const demandValue = demand?.value;
  if (demandValue === "low") {
    return <IconNegative width="25" />;
  } else if (demandValue === "medium") {
    return <IconWarning width="25" />;
  } else {
    return <IconPositive width="25" />;
  }
};

export const renderPayIcon = (pay) => {
  const payValue = pay?.value;
  if (payValue === "low") {
    return <IconNegative width="25" />;
  } else if (payValue === "high") {
    return <IconPositive width="25" />;
  } else {
    return <IconWarning width="25" />;
  }
};

export const renderHelpIcon = (getting_help) => {
  const helpValue = getting_help?.value;
  if (helpValue === "easy") {
    return <IconPositive width="25" />;
  } else if (helpValue === "medium") {
    return <IconWarning width="25" />;
  } else {
    return <IconNegative width="25" />;
  }
};
