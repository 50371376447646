import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import TagListing from "../components/Language/TagListing";

export default function EcosystemTemplate({ pageContext, data }) {
  const { ecosystem } = pageContext;
  const languageEdges = data.allLanguagesJson.edges;
  return (
    <Layout>
      <Helmet
        title={`Languages with "${ecosystem}" ecosystems| ${config.siteTitle}`}
      />
      <TagListing
        tag={ecosystem}
        category="Ecosystem"
        allLanguagesJson={data.allLanguagesJson}
        languageEdges={languageEdges}
        description="A small ecosystem implies there aren't many people writing in the language, meaning fewer people to engage in discussion and fewer examples to follow. In contrast, there are plenty of people talking about widely adopted languages, but more people often means more misinformation within larger ecosystems."
      />
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query EcosystemPage($ecosystem: String) {
    allLanguagesJson(
      limit: 1000
      sort: { fields: [ecosystem___value], order: DESC }
      filter: { ecosystem: { value: { eq: $ecosystem } } }
    ) {
      totalCount
      edges {
        node {
          slug
          name
          description
          used_for
          getting_help {
            value
          }
          pay {
            value
          }
          barrier_to_entry {
            value
          }
          demand {
            value
          }
        }
      }
    }
  }
`;
