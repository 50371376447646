import React from "react";
import { Link } from "gatsby";
const _ = require("lodash");
import {
  renderBarrierToEntryIcon,
  renderHelpIcon,
  renderDemandIcon,
  renderPayIcon,
} from "../../components/Language/RenderIcons";
import renderLanguageLogo from "./RenderLanguageLogo";

function LanguageCard({ language, type }) {
  const { name, slug, barrier_to_entry, demand, pay, getting_help, used_for } =
    language;

  const renderButton = () => {
    if (type === "list") {
      return (
        <div className="pt-4">
          <Link
            className="flex w-full p-2 hover:bg-slate-900 text-slate-900 hover:text-white border-2 border-slate-900 font-bold tracking-wider justify-center cursor-pointer"
            key={name}
            style={{ textDecoration: "none" }}
            to={`/language/${slug}`}
          >
            Visit {name}
          </Link>
        </div>
      );
    }
  };

  const typeClass = () => {
    if (type === "list") {
      return "pr-6";
    }
  };

  const heightClass = () => {
    if (type === "list") {
      return "h-full";
    }
  };

  const tagLink = (value) => {
    return (
      <Link
        key={value}
        style={{ textDecoration: "none" }}
        to={`/used-for/${_.kebabCase(value)}`}
      >
        {" "}
        {value}{" "}
      </Link>
    );
  };

  return (
    <div
      className={`flex flex-col items-between bg-gray-100 p-6 ${heightClass()}`}
    >
      <div className="flex w-full flex-col items-left pb-6 justify-start text-left">
        <div className="flex justify-start w-1/2 text-left items-start pb-4">
          {renderLanguageLogo(name)}
        </div>
        <div className="w-full">
          <h1 className="pb-0">{name}</h1>
          {used_for && (
            <p className="w-full prose prose-sm">
              Used for {used_for.map(tagLink)}{" "}
            </p>
          )}
        </div>
      </div>

      <div className="flex flex-row w-full flex-wrap place-items-end flex-1">
        <div className="flex flex-row w-full prose-sm items-center pb-2">
          {renderBarrierToEntryIcon(barrier_to_entry?.value)}
          <p className="m-0 pl-2">
            <span className="capitalize font-bold">
              {barrier_to_entry?.value}
            </span>{" "}
            barrier to entry
          </p>
        </div>
        <div className="flex flex-row w-full prose-sm items-center pb-2">
          {renderDemandIcon(demand?.value)}
          <p className="m-0 pl-2">
            <span className="capitalize font-bold">{demand?.value}</span>{" "}
            demand
          </p>
        </div>
        <div className="flex flex-row w-full prose-sm items-center pb-2">
          {renderPayIcon(pay?.value)}
          <p className="m-0 pl-2">
            <span className="capitalize font-bold">{pay?.value}</span> pay
          </p>
        </div>
        <div className="flex flex-row w-full prose-sm items-center pb-2">
          {renderHelpIcon(getting_help?.value)}
          <p className="m-0 pl-2">
            <span className="capitalize font-bold">
              {getting_help?.value}
            </span>{" "}
            to find help
          </p>
        </div>
      </div>

      {renderButton()}
    </div>
  );
}

export default LanguageCard;
